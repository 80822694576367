<template>
  <el-dialog
    class="tipVisible"
    :visible.sync="dialogVisible"
    width="570px"
    :close-on-click-modal="false"
    append-to-body
  >
    <div class="tip-cell">
      <div class="tip-cell-label">
        请在完成<br/>
        信息认证后学习
      </div>
      <div class="tip-cell_row" v-if="!this.$store.state.userInfo.email">
        <img src="@/assets/images/tips/email.png" alt="" />
        <div class="flex_bd">邮箱认证</div>
        <button class="btn btn-go" @click="bindEmail()">添加邮箱</button>
      </div>
      <div
        class="tip-cell_row"
        v-if="
          this.$store.state.userInfo.status.value != 2 &&
          this.$store.state.userInfo.status.value != 3
        "
      >
        <img src="@/assets/images/tips/ver.png" alt="" />
        <div class="flex_bd">实名认证</div>
        <button class="btn btn-go" @click="certficate()">实名认证</button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    certficate() {
      this.$router.push({
        path: "/center/certificate",
      });
    },
    bindEmail() {
      this.$router.push({
        path: "/center/account",
      });
    },
  },
  mounted() {},
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("change-visible", val);
      },
    },
  },
};
</script>
<style scoped lang="scss">
.tipVisible ::v-deep .el-dialog {
  background: linear-gradient(0deg, #ffffff 50%, #cbdcfa 100%);
  border-radius: 10px;
}
.tipVisible ::v-deep .el-dialog__body {
  padding: 0;
  margin-top: -30px;
  background: url(../../../assets/images/tips/tbg.png) right top no-repeat;
}
.tip-cell {
  padding: 40px;
}

.tip-cell-label {
  font-size: 28px;
  color: #03418a;
  margin-bottom: 70px;
}

.tip-cell_row {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
  .flex_bd {
    flex: 1;
  }
}
.tip-cell_row img {
  margin-right: 15px;
}
.tip-cell_row .btn-go {
  background: #0180F7;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-size: 16px;
  border: 0;
  outline: 0;
  min-width: 140px;
  text-align: center;
  cursor: pointer;
  padding: 0 20px;
}
</style>
