<template>
  <div id="app">
    <div class="center-tit">
      我的课程<span>/My Lesson</span>
      <!--            <ul>-->
      <!--                <li-->
      <!--                        :class="idx == index ? 'active' : ''"-->
      <!--                        :key="index"-->
      <!--                        @click="tabNav(item.status)"-->
      <!--                        v-for="(item, index) in tabList"-->
      <!--                >-->
      <!--                    {{ item.name }}-->
      <!--                </li>-->
      <!--            </ul>-->
    </div>
    <div class="list-con">
      <div v-for="(item, index) in list" :key="index" class="list">
        <img
          :src="item.cover && item.cover"
          class="img"
          style="cursor: pointer"
          @click="tolearn(item)"
        />
        <div class="l-title">
          <div
            v-if="item.superType.value == '0'"
            class="l-course"
            @click="tolearn(item)"
          >
            班课规划
          </div>
          <div v-else class="l-plan">课 程</div>
          <h2 style="cursor: pointer" @click="tolearn(item)">
            {{ item.name }}
          </h2>
        </div>

        <!--                <p>-->
        <!--                    有效期：{{-->
        <!--                    item.started-->
        <!--                    ? `${transformTstoString(-->
        <!--                    new Date(item.startTime).getTime() / 1000-->
        <!--                    )}~${transformTstoString(-->
        <!--                    new Date().getTime() / 1000 + 86400 * item.expirationInDay-->
        <!--                    )}`-->
        <!--                    : item.expirationInDay + "天"-->
        <!--                    }}-->
        <!--                </p>-->
        <p style="margin-top: 10px; display: flex; align-items: center">
          <img src="@/assets/imgs/date.png" />

          <span
            v-if="
              item.startTime &&
              item.startTime != '' &&
              item.terminationTime &&
              item.terminationTime != '' &&
              item.started == false
            "
          >
            开课时间：{{ item.startTime.substring(0, 10) }}
          </span>
          <span
            v-else-if="
              !item.startTime && !item.terminationTime && item.started == false
            "
          >
            手动开课：自定义开课时间
          </span>
          <span
            v-else-if="
              item.startTime &&
              item.startTime != '' &&
              !item.terminationTime &&
              item.started == false
            "
          >
            此课程在 {{ item.startTime.substring(0, 10) }} 后开课
          </span>
          <span v-else>
            有效期：{{ item.startTime && item.startTime.substring(0, 10) }} ~
            {{ item.terminationTime && item.terminationTime.substring(0, 10) }}
          </span>

          <!--                    <span v-if="!item.startTime || !item.terminationTime">-->
          <!--                      手动开课：自定义开课时间-->
          <!--                    </span>-->
          <!--                    <span v-if="item.startTime || item.terminationTime">-->
          <!--                      有效期：{{item.startTime && item.startTime.substring(0, 10) }}~-->
          <!--                      {{ item.terminationTime && item.terminationTime.substring(0, 10) }}-->
          <!--                    </span>-->
        </p>
        <div>
          <a @click="tolearn(item)">{{
            item.started ? "继续学习" : "开始学习"
          }}</a>
          <!-- <a
            style="
              top: 72px;
              background: unset;
              color: #535353;
              width: 5em;
              right: 61px;
            "
            @click="courseScore(item)"
            >课程评价</a
          >
          <span
            style="
              border-top: 1px solid #000;
              width: 4em;
              position: absolute;
              right: 68px;
              top: 93px;
            "
          ></span> -->
        </div>
      </div>
      <div style="text-align: center; margin-bottom: 20px">
        <el-pagination
          v-if="total"
          :current-page.sync="currpage"
          :page-size="pageSize"
          :total="total"
          background
          layout="prev, pager, next"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!--  确定按钮+取消按钮 -->
    <div v-if="dialogFormVisibleTiming" class="submit-con">
      <div class="box zhuan-box pay-box" style="height: 215px; width: 345px">
        <div class="c-title">
          <div>确定立即开始课程？</div>
          <div>
            课程有效期：{{ date.year }}年{{ date.month }}月{{ date.day }}日~{{
              date.exyear + "年" + date.exmonth + "月" + date.exday + "日"
            }}
          </div>
        </div>
        <div class="c-btn">
          <div @click="goIsNotPay()">确 定</div>
          <div @click="dialogFormVisibleTiming = false">取 消</div>
        </div>
      </div>
    </div>

    <!--  一个确定按钮 -->
    <div v-if="dialogFormVisible" class="submit-con">
      <div class="box zhuan-box pay-box" style="height: 200px; width: 320px">
        <div class="c-title">
          <div v-html="title"></div>
        </div>
        <div class="c-btn">
          <div @click="dialogFormVisible = false">确定</div>
        </div>
      </div>
    </div>
    <div
      class="mask"
      v-show="scoreFlag"
      @click="
        scoreFlag = false;
        msg = '';
      "
      style="z-index: 0 !important"
    ></div>
    <div class="dialog" v-show="scoreFlag">
      <span>课程评价</span>
      <br />
      <!-- <textarea name="cous" id="cous" cols="30" rows="10"></textarea> -->
      <textarea
        name="cous"
        id="cous"
        placeholder="请输入课程评价"
        v-model="msg"
      ></textarea>
      <div
        class="_dialog_wrap"
        :style="{ display: 'flex', justifyContent: 'center' }"
      >
        <button
          @click="postScore"
          style="
            background: linear-gradient(to right, rgb(236, 124, 70), #fcb045);
            cursor: pointer;
            margin-right: 30px;
          "
        >
          确认</button
        ><button
          @click="
            scoreFlag = false;
            msg = '';
          "
          style="
            background-color: unset;
            color: #535353;
            border: 1px solid #ccc;
            cursor: pointer;
          "
        >
          取消
        </button>
      </div>
    </div>
    <tipsDialog
      :visible="dialogFormVisibleMoving"
      @change-visible="(val) => (dialogFormVisibleMoving = val)"
    ></tipsDialog>
    <!-- <div v-if="dialogFormVisibleMoving" class="submit-con">
      <div class="box zhuan-box pay-box" style="height: 200px; width: 320px">
        <template>
          <div class="c-title">
            您还未{{
              this.$store.state.userInfo.status.value != 2 &&
              this.$store.state.userInfo.status.value != 3
                ? "实名认证"
                : ""
            }}
            {{
              this.$store.state.userInfo.status.value != 2 &&
              this.$store.state.userInfo.status.value != 3 &&
              !this.$store.state.userInfo.email
                ? "和"
                : ""
            }}
            {{
              this.$store.state.userInfo.email ? "" : "绑定邮箱"
            }}，实名认证方可学习
          </div>
          <div
            class="c-btn"
            v-if="
              this.$store.state.userInfo.status.value != 2 &&
              this.$store.state.userInfo.status.value != 3
            "
          >
            <div @click="certficate()">立即实名认证</div>
            <div @click="bindEmail()" v-if="!this.$store.state.userInfo.email">
              立即绑定邮箱
            </div>
          </div>
        </template>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  getCourseList,
  startUserCourse,
  comment,
} from "../../../service/index";
import tipsDialog from "@/views/1_user/components/tipsDialog.vue";
export default {
  components: { tipsDialog },
  data() {
    return {
      date: {},
      openingTime: {},
      msg: "",
      scoreFlag: false,
      idx: 0,
      list: [],
      tabList: [
        {
          status: 0,
          name: "课程",
          checked: true,
        },
        {
          status: 1,
          name: "规划",
          checked: false,
        },
      ],
      pageSize: 10, //每一页的数据
      currpage: 1, //当前页
      total: 0, //所有的数据
      item: null,
      title: "",
      dialogFormVisible: false,
      dialogFormVisibleTiming: false,
      dialogFormVisibleMoving: false,
    };
  },
  watch: {
    scoreFlag(val) {
      //关闭弹窗 清空内容
      if (!val) {
        this.msg = "";
      }
    },
  },
  async created() {
    await this.$store.dispatch("queryUserInfo");
    console.log(this.userInfo);
    this.queryMyCourse();
  },
  async mounted() {
    this.path = this.$route.path;
  },

  methods: {
    courseScore(i) {
      console.log("@@@i@@@", i);
      sessionStorage.courseScore = JSON.stringify(i);
      this.scoreFlag = true;
    },
    async postScore() {
      let i = JSON.parse(sessionStorage.courseScore);
      let d = {
        content: this.msg,
        objectId: i.courseId,
        pid: 0,
        sectionResourceType: "",
        topLevelId: 0,
        type: i.superType.value == 0 ? 3 : 1,
        visibility: true,
      };
      let res = await comment(d);
      if (res.message) {
        this.$message.success("评价成功");
        this.msg = "";
        this.scoreFlag = false;
      } else {
        this.$message(res.message);
      }
    },
    queryMyCourse() {
      getCourseList(this.currpage, this.pageSize).then((res) => {
        console.log(res, this.userInfo, "aaa");
        this.list = res.result.records;
        this.total = res.result.total;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryMyCourse();
    },
    handleCurrentChange(val) {
      this.currpage = val;
      this.queryMyCourse();
    },

    tolearn(item) {
      if (
        (this.$store.state.userInfo.status.value != 2 &&
          this.$store.state.userInfo.status.value != 3) ||
        !this.$store.state.userInfo.email
      ) {
        this.dialogFormVisibleMoving = true;
        return;
      }
      if (!item.started) {
        // this.$confirm("");
        let startType = "";
        if (
          item.startTime &&
          item.startTime != "" &&
          item.terminationTime &&
          item.terminationTime != "" &&
          item.started == false
        ) {
          startType = "定时";
        } else if (
          !item.startTime &&
          !item.terminationTime &&
          item.started == false
        ) {
          startType = "自定义";
        } else if (
          item.startTime &&
          item.startTime != "" &&
          !item.terminationTime &&
          item.started == false
        ) {
          startType = "延迟";
        }
        this.item = item;
        switch (startType) {
          case "自定义":
            // this.$confirm(
            //     `确定立即开始课程？<br>课程有效期：${this.transformTstoString(
            //         new Date().getTime() / 1000
            //     )}~${this.transformTstoString(
            //         new Date().getTime() / 1000 + 86400 * item.expirationInDay
            //     )}`,
            //     "提示",
            //     {
            //         dangerouslyUseHTMLString: true,
            //     }
            // ).then(async () => {
            //     console.log("确定");
            //     startUserCourse(
            //         this.userInfo.id,
            //         item.id,
            //         this.transformTstoEntireString(new Date().getTime() / 1000)
            //     ).then((res) => {
            //         console.log(res);
            //     });
            // });
            let myDate = new Date();
            console.log(myDate);
            this.date.year = myDate.getFullYear();
            console.log(myDate.getMonth());
            this.date.month = myDate.getMonth() + 1;
            this.date.day = myDate.getDate();
            let myDate2 = new Date();
            let date2 = myDate2.setDate(
              myDate.getDate() + item.expirationInDay
            );
            date2 = new Date(date2);
            this.date.exyear = date2.getFullYear();
            this.date.exmonth = date2.getMonth() + 1;
            this.date.exday = date2.getDate();
            this.dialogFormVisibleTiming = true;
            break;
          case "定时":
            // this.$confirm(
            //     `此课程会在${this.transformTstoString(
            //         new Date(item.startTime).getTime() / 1000
            //     )}自动开课<br>不要着急哦~`,
            //     "提示",
            //     {
            //         dangerouslyUseHTMLString: true,
            //         showCancelButton: false,
            //     }
            // );
            this.title = `此课程会在${item.startTime.substring(0, 10)}自动开课`;
            this.dialogFormVisible = true;
            break;
          case "延迟":
            // this.$confirm(
            //     `为保证学习效果，此课程在${this.transformTstoString(
            //         new Date(item.startTime).getTime() / 1000
            //     )}后，方可开课`,
            //     "提示",
            //     {
            //         dangerouslyUseHTMLString: true,
            //         showCancelButton: false,
            //     }
            // );
            this.title = `为保证学习效果<br/>此课程在${item.startTime.substring(
              0,
              10
            )}后，方可开课`;
            this.dialogFormVisible = true;
            break;
        }
      } else {
        this.$store.state.courseName = item.name;
        var j = 100;
        setInterval(() => {
          j = j * 100;
        }, 10000);

        this.$router.push({
          path:
            item.superType.value === 0
              ? `/lessoncenter?userCourseId=${item.courseId}`
              : "/course/video",
          query: {
            courseId: item.courseId,
            superType: item.superType.value,
            courseName: item.name,
            userCourseId: item.id,
          },
        });
      }
    },
    certficate() {
      this.$router.push({
        path: "/center/certificate",
      });
    },
    bindEmail() {
      this.$router.push({
        path: "/center/account",
      });
    },

    goIsNotPay() {
      console.log(this.item);
      startUserCourse(
        this.userInfo.id,
        this.item.id,
        this.transformTstoEntireString(new Date().getTime() / 1000)
      ).then((res) => {
        console.log(res);
        if (res.success) {
          this.dialogFormVisibleTiming = false;
          this.$store.state.courseName = this.item.name;
          this.$router.push({
            path:
              this.item.superType.value == 0
                ? `/lessoncenter?userCourseId=${this.item.courseId}`
                : "/course/video",
            query: {
              courseId: this.item.courseId,
              superType: this.item.superType.value,
              courseName: this.item.name,
              userCourseId: this.item.id,
            },
          });
        }
      });
    },
    tabNav(index) {
      this.tabList.forEach((element, i) => {
        if (i == index) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
      this.idx = index;
      // this.setOrdersByStatus();
    },
    transformTstoString(unixtime) {
      var dateTime = new Date(parseInt(unixtime) * 1000);
      var year = dateTime.getFullYear();
      var month = dateTime.getMonth() + 1;
      if (month > 0 && month < 10) {
        month = "0" + month;
      }
      var day = dateTime.getDate();
      if (day > 0 && day < 10) {
        day = "0" + day;
      }
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var second = dateTime.getSeconds();
      var now = new Date();
      var now_new = Date.parse(now.toDateString()); //typescript转换写法
      var milliseconds = now_new - dateTime;
      var timeSpanStr = year + "年" + month + "月" + day + "日";
      return timeSpanStr;
    },
    transformTstoEntireString(time) {
      var date = new Date(time * 1000);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      var timeStr =
        y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      return timeStr;
    },
  },
  computed: mapState(["userInfo"]),
};
</script>

<style>
.mask {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dialog {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 340px;
  background-color: #fff;
  border-radius: 8px;
  color: #000;
  font-size: 20px;
  padding: 30px 30px 20px 30px;
}
.dialog textarea {
  border: 1px solid #ccc;
  resize: none;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 10px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  margin: 25px 0;
  border-radius: 5px;
  font-family: "meiruanyahei";
}
.dialog button {
  background-color: orange;
  width: 75px;
  height: 35px;
  border: none;
  border-radius: 5px;
  margin-right: 30px;
  color: #fff;
  font-size: 16px;
}
.dialog div {
  text-align: center;
}
.el-message-box__message {
  text-align: center;
}
</style>
<style scoped>
.l-title {
  display: flex;
}

.l-title div {
  font-size: 12px;
  margin-top: 40px;
  color: white;
  border-radius: 30px;
  text-align: center;
  height: 18px;
  line-height: 18px;

  margin-right: 10px;
}

.l-course {
  background: #fa6400;
  width: 75px;
  letter-spacing: 1px;
}

.l-plan {
  background: #fcb045;
  width: 50px;
}

.c-title {
  text-align: center;
  padding: 20px;
}

.c-title div:nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  line-height: 30px;
}

.c-title div:nth-child(2) {
  margin-top: 12px;
  font-weight: 400;
  color: #fa6400;
  cursor: pointer;
}

.c-btn {
  display: flex;
  margin-bottom: 20px;
  margin-left: 16px;
  margin-top: 30px;
}

.c-btn div {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 22px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  border-radius: 2px;
}

.c-btn div {
  color: white;
  background: linear-gradient(to left, #fd1d1d, #fcb045);
  margin-right: 20px;
  cursor: pointer;
  display: inline;
}

/* .c-btn div:nth-child(2) {
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
} */

.list-con {
  margin-bottom: 10px;
}
</style>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #fa6400;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #fa6400;
}
._dialog_wrap button {
  margin-right: 0px;
}
</style>
